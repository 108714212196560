import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Redirect } from 'react-router-dom';
import TestService from "../services/TestService";
import Progress from "./progress";
import Question from "./question";

import { FullLoading } from './Loading'

const testService = new TestService();

const TestPage = () => {
    const [testData, setTestData] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [current, setCurrent] = useState({})
    const [respuesta, setRespuesta] = useState({})


    useEffect(() => {
        testService.getAllAsk().then((json) => {
            setCurrent(json.shift())
            setTestData(json)
            setLoading(false)
        })
        .catch(function (error) {
            setError(true)
            console.log(error);
        });
    }, []);

    const handleClick = function () {
        if (respuesta.RespuestaId) {
            setLoading(true)
            testService.saveAnswer(respuesta).then((json) => {
                setCurrent(testData.shift())
                setRespuesta({})
                setLoading(false)
            })
            .catch(function (error) {
                setError(true)
                console.log(error);
            });
        } else {
            console.log('no hay') //Mostrar Mensaje
        }
    };

    const handleChange = function (e) {
        const { value } = e.currentTarget;
        setRespuesta({ PreguntaId: Number(current.id), RespuestaId: Number(value) });
    };

    if (error) {
        return <h2>error...</h2>
    }

    if (!loading && current === undefined) {
        return <Redirect to='/cierre' />
    }

    if (loading) {
        return <FullLoading />
    }

    return (
        <>
            <Container
                as={"section"}
                className="flex-grow-1 d-flex flex-column justify-content-center"
            >
                <Row className="justify-content-center">
                    <Col md={5}>
                        <Question
                            qText={current.nombre}
                            qOptions={current.respuestas}
                            isLoading={loading}
                            handleChange={handleChange}
                            qId={current.id}
                        />
                    </Col>
                </Row>
            </Container>

            <Container as={"section"}>
                <Row>
                    <Col>
                        <Progress max={180} current={current.orden} handleClick={handleClick} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TestPage
