import React, { useReducer } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Bienvenida from './components/Bienvenida';
import Cierre from './components/Cierre';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import Login from "./components/Login";
import UserData from "./components/UserData";
import Resultados from "./components/Resultados";

import Test from './components/Test'

/*Estado login*/
export const AuthContext = React.createContext();

let initialState = {};

const exp = localStorage.getItem('expiration');
if (exp && (new Date(exp) > new Date())) {
    initialState = {
        isAuthenticated: (localStorage.getItem('token')) ? true : false, //TODO
        user: JSON.parse(localStorage.getItem('user')) ?? {},
        token: localStorage.getItem('token'),
    };
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("user", JSON.stringify(action.payload.user));
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem('expiration', action.payload.expiration)
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token
            };
        case "LOGOUT":
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        case "DATAUP":
            return {
                ...state
            }
        case "ENDTEST":
            return {
                ...state
            }
        default:
            return state;
    }
};
/*Fin Estado login*/

const App = () => {
    //static displayName = App.name;
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AuthContext.Provider
            value={{
                state,
                dispatch
            }}
        >
            <Layout>
                <Route exact path='/' component={Login} />
                <Route path='/login' component={Login} />
                <AuthorizeRoute path='/test' component={Test} />
                <AuthorizeRoute path='/userdata' component={UserData} />
                <AuthorizeRoute path='/resultados' component={Resultados} />
                <AuthorizeRoute path='/bienvenida' component={Bienvenida} />
                <AuthorizeRoute path='/cierre' component={Cierre} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        </AuthContext.Provider>
    );
}

export default App
