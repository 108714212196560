import AuthService from "./AuthService";

export default class TestService {
    getAllAsk() {
        const token = AuthService.getToken();

        const headers = {
            'Accept': 'application/json;charset=utf-8',
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': !token ? "" :  ("Bearer " + token) 
        }

        return fetch('api/preguntas', {
            headers: headers
        })
        .then((response) => response.json());
    }

    saveAnswer(answ) {
        const token = AuthService.getToken();

        const headers = {
            'Accept': 'application/json;charset=utf-8',
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': !token ? "" : ("Bearer " + token)
        }

        return fetch('api/RespuestasLog', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(answ)
        }).then((response) => response.json())
    }

    login(user) {
        return fetch('api/Auth/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json; charset=utf-8',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(user)
        }).then((response) => { console.log(response); return response.json() })
    }
}
