const key = 'token';

class AuthService {
    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    saveToken(token) {
        localStorage.setItem(key, token);
    }

    getToken() {
        return localStorage.getItem(key);
    }

    isLoging() {
        return !!localStorage.getItem(key);
    }
}

const AuthServ = new AuthService();

export default AuthServ