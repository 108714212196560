import React from "react"

import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"

//import { motion, AnimatePresence } from "framer-motion"

//const variants = {
//    hidden: { opacity: 0, scale: 0 },
//    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
//}

const Question = ({ qText, qOptions, isLoading, handleChange, qId }) => {

    if (isLoading) {
        return (
            <Card className="shadow-lg border-0" body>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Cargando...
            </Card>
        )
    }

    return (
        <Card className="shadow-lg border-0">
            <Card.Header>
                {qText}
            </Card.Header>
            <Card.Body>
                    {qOptions.map((e, i) => {
                        return (
                            <div key={`${qId}-${e.id}`} className="inputGroup">
                                <input
                                    id={e.id}
                                    name="radio"
                                    type="radio"
                                    value={e.id}
                                    onChange={handleChange}
                                />
                                <label htmlFor={e.id}>{e.nombre}</label>
                            </div>
                        )
                    })}
            </Card.Body>
        </Card>
    )
}

export default Question
