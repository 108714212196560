import React, { useState } from "react"

import { FullLoading } from './Loading' 

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import useAxios from 'axios-hooks'
import AuthService from "../services/AuthService";
import ChartistGraph from 'react-chartist';

const Resultados = () => {
    const [reSend, setReSend] = useState(false);
    const token = AuthService.getToken();

    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: '/api/resultados',
            headers: { 'Authorization': !token ? "" : ("Bearer " + token)  },
        },
        { useCache: false}
    )

    const [{ loading: sendLoa}, post] = useAxios(
        {
            url: '/api/resultados/resendmail',
            headers: { 'Authorization': !token ? "" : ("Bearer " + token) },
        },
        { manual: true }
    );

    if (loading) return <FullLoading />
    if (error) return <p>Error!</p>

    const handlerClick = () => {
        post({
            data: {},
        }).then(() => {
            setReSend(true);
        });
    };

    const bodyTable = data.tabla.map((i,x) => (
        <tr key={x}>
            <td>{i.nombre}</td>
            <td>{i.codigo}</td>
            <td>{i.valor}</td>
            <td>{i.valorBaremos}</td>
        </tr>
    ));

    var dataChart = {
        labels: data.tabla.map((i) => i.nombre),
        series: [
            [...data.tabla.map((i) => i.valorBaremos)]
        ]
    };

    var options = {
        seriesBarDistance: 30,
        horizontalBars: true,
        axisY: {
            offset: 100
        },
        height: '600px'
    }

    return (
        <Container
            as={"section"}
            className="flex-grow-1 d-flex flex-column justify-content-center"
        >
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow-lg border-0 mt-4">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h4>Resultados</h4>
                            <button disabled={reSend} onClick={handlerClick} className="btn btn-primary btn-sm">
                                reenviar correo
                            </button>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {data.txt1.map((v, i) => <p key={i}>{v}</p>)}
                            </Card.Text>
                            <hr/>
                            <Card.Text>
                                <ul>
                                    {data.txt2.map((v, i) => <li key={i}>{v}</li>)}
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow-lg border-0 mt-4">
                        <Card.Header><h4>Gráfico</h4></Card.Header>
                        <Card.Body>
                            <ChartistGraph data={dataChart} options={options} type={'Bar'} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow-lg border-0 my-4">
                        <Card.Header><h4>Tabla Resultados</h4></Card.Header>      
                        <Row>
                            <Col>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Especialidad</th>
                                            <th scope="col">Codigo</th>
                                            <th scope="col">Suma Bruta</th>
                                            <th scope="col">Baremos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bodyTable}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Resultados
