import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'

import { AuthContext } from "../../App";

const AuthorizeRoute = ({ component: Component, ...rest }) => {
    const { state: authState } = useContext(AuthContext);

    const redirectUrl = `/login?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`

    const finalComponent = (props)=>((authState.isAuthenticated) ? <Component {...props} /> : <Redirect to={redirectUrl} />);

    return <Route {...rest} render={finalComponent} />
}

export default AuthorizeRoute
