import React from "react"

import ProgressBar from "react-bootstrap/ProgressBar"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Progress = ({ max, current, handleClick }) => {
    const wBar = (current * 100) / max

    return (
        <Card className="shadow mb-4" body>
            <Row className="align-items-center">
                <Col>
                    <ProgressBar now={wBar} label={`${current}`} />
                </Col>
                <Col xs={"auto"}>
                    <button onClick={handleClick} className="btn btn-primary btn-sm">
                        Siguiente
                    </button>
                </Col>
            </Row>
        </Card>
    )
}

export default Progress
