import React, { useState } from "react"
import { Redirect } from "react-router-dom"

import { CardLayout } from "./Layout"

const Bienvenida = () => {

    const [next, setNext] = useState(false)

    const handelSubmit = e => {
        setNext(true);
    }

    if (next) {
        return <Redirect to="/test" />
    }

    return (
        <CardLayout>
            <h2> BIENVENIDO</h2>
            <hr />
            <p>Hoy trabajarás en  tus preferencias e intereses vocacionales.
            Necesitas un espacio tranquilo, que te permita enfocar toda tu atención y energía, se recomienda estar libre de distracciones.
            Al seleccionar tu respuesta hazlo de forma sincera y sin ningun tipo de prejucio:( dificultad, prestigio, dinero, mito u otra barrera.)
            Debes marcar solo UNA respuesta de las opciones disponibles, la que mejor describa el como te sientes frente a la frase desplegada en la pantalla. No hay respuestas correctas e incorrectas. Sólo se tú mismo.
            Estas a unos pasos de conocer cual es tu vocación y pasión.
            EXITOS.</p>
            <hr />
            <button className="btn btn-primary" onClick={handelSubmit}>
                Continuar
            </button>
        </CardLayout>
    )
}

export default Bienvenida
