import React, { useState } from "react"
import { Redirect } from 'react-router-dom';

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

import Card from "react-bootstrap/Card"

import { FullLoading } from './Loading'

import { AuthContext } from "../App";
import useAxios from 'axios-hooks'

const Login = () => {
    const { state: authState, dispatch } = React.useContext(AuthContext);
    const [formValues, setFormValue] = useState({});

    const [{ loading, error }, post] = useAxios(
        {
            method: 'post',
            url: '/api/auth/login',
            headers: {},
        },
        { manual: true }
    );

    const handelSubmit = (e) => {
        e.preventDefault();

        post({
            data: formValues,
        }).then(({ data }) => {
            if (data.token) {
                dispatch({
                    type: "LOGIN",
                    payload: data
                });
            }
        });
    }

    const handelChange = (e) => {
        const { name, value } = e.currentTarget;
        setFormValue((prev) => { return { ...prev, [name]: value } });
    }

    if (authState.isAuthenticated) {
        if (!authState.user.dataUpdate) {
            return (<Redirect to='/userdata' />)
        } else if (authState.user.testComplete) {
            return (<Redirect to='/resultados' />)
        } else {
            return (<Redirect to='/test' />)
        }
    }

    if (loading) {
        return <FullLoading />
    }

    return (
        <Container as={"section"} className="my-auto">
            <Row className="justify-content-center">
                <Col md={5}>
                    <Card body>
                        <p>Ingrese su codigo para comenzar</p>
                        <hr/>
                        <form onSubmit={handelSubmit}>
                            <div className="form-group">
                                <label htmlFor="Password">Codigo</label>
                                <input
                                    onChange={handelChange}
                                    type="text"
                                    className="form-control"
                                    id="Password"
                                    name="Password"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Comenzar
                            </button>
                        </form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Login
