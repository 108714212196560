import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import { FullLoading } from './Loading' 

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

import Form from "react-bootstrap/Form"
import Card from "react-bootstrap/Card"

import useAxios from 'axios-hooks'
import AuthService from "../services/AuthService";

const UserData = () => {
    const [validated, setValidated] = useState(false);
    const [isComplete, setIsComplete] = useState(false)
    const [valuesForm, setvaluesForm] = useState({})

    const token = AuthService.getToken();

    const [{ data, loading, error }, refetch] = useAxios(
        {
            method: 'post',
            url: '/api/auth/datauser',
            headers: { 'Authorization': !token ? "" : ("Bearer " + token) },
        },
        { manual: true }
    )

    const handelSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            var dataForm = {
                'Genero': Number(form.elements.genero.value),
                'Email': form.elements.email.value
            };

            refetch({
                data: dataForm,
            }).then(d => { console.log(d) });
        }

        setValidated(true);
    };

    const handlechange = (e) => {
        const { name, value } = e.currentTarget;
        setvaluesForm(prev => ({ ...prev, [name]:value}))
    }

    if (loading) return <FullLoading />
    if (error) return <p>Error!</p>

    if ( data === "" && error === null && loading === false) {
        return <Redirect to="/bienvenida" />
    }

    return (
        <Container as={"section"} className="my-auto">
            <Row className="justify-content-center">
                <Col sm={12} md={8}>
                    <Card body>
                        <Form noValidate validated={validated} onSubmit={handelSubmit}>
                            <Form.Group controlId="nombre" name="nombre">
                                <Form.Label>Nombres</Form.Label>
                                <Form.Control type="text" placeholder="Nombres" required />
                            </Form.Group>
                            <Form.Group controlId="apellido" name="apellido">
                                <Form.Label>Apellidos</Form.Label>
                                <Form.Control type="text" placeholder="Apellidos" required />
                            </Form.Group>

                            <Form.Group controlId="email" name="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" required />
                            </Form.Group>

                            <Form.Row>
                                <Col>
                                    <Form.Group controlId="fechaNacimiento" name="fechaNacimiento">
                                        <Form.Label>Fecha nacimiento</Form.Label>
                                        <Form.Control type="date" required />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="genero" name="genero">
                                        <Form.Label>Género</Form.Label>
                                        <Form.Control as="select" required defaultValue="">
                                            <option hidden disabled value></option>
                                            <option value="1">Femenino</option>
                                            <option value="2">Masculino</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Group controlId="institucion">
                                        <Form.Label>Institución Educativa</Form.Label>
                                        <Form.Control type="text" placeholder="Institución Educativa" required/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="grado" name="grado">
                                        <Form.Label>Grado que cursa actualmente</Form.Label>
                                        <Form.Control as="select" required defaultValue="">
                                            <option hidden disabled value></option>
                                            <option value="1">3ero</option>
                                            <option value="2">4to</option>
                                            <option value="3">5to</option>
                                            <option value="4">Secundaria completa</option>
                                            <option value="5">Tecnico</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <hr />
                            <button className="btn btn-primary" type="submit">
                                Guardar
                            </button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default UserData
