import React from "react"
import PropTypes from "prop-types"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

import { NavMenu } from "./NavMenu"

export const Layout = ({ children }) => {
    return (
        <>
            <NavMenu />
            <main
                className="d-flex flex-column"
                style={{ minHeight: "calc(100vh - 2.5rem - 3.6rem)" }}
            >
                {children}
            </main>
            <footer
                className="p-2 text-muted"
                style={{ backgroundColor: "rgb(30, 30, 45)" }}
            >
                <Container>
                    <Row>
                        <Col>
                            © {new Date().getFullYear()}, Built with ❤ by{" "}
                            <a href="#">Naomi</a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export const CardLayout = ({ children }) => (
    <Container as={"section"} className="my-auto">
        <Row className="justify-content-center">
            <Col sm={12} md={8}>
                <Card body>
                    {children}
                </Card>
            </Col>
        </Row>
    </Container>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
