import React, { useState } from "react"
import { Redirect } from "react-router-dom"

import { CardLayout } from "./Layout"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

import Card from "react-bootstrap/Card"

const Cierre = () => {

    const [next, setNext] = useState(false)

    const handelSubmit = e => {
        setNext(true);
    }

    if (next) {
        return <Redirect to="/resultados" />
    }

    return (
        <CardLayout>
            <h2> HAZ COMPLETADO EL TEST CON EXITO</h2>
            <hr/>
            <p>La herramienta para medir tus preferencias y aptitudes vocacionales ha tomado tus respuestas y serán procesadas para entregar un resultado totalmente personalizado.
Te invitamos a leer cuidadosamente el informe final.
Recueda que la elección esta en tus manos, y con estos resultados estarás mas confiado de elegir tu camino a seguir</p>										
            <hr />
            <button className="btn btn-primary" onClick={handelSubmit}>
                Ver Resultados
            </button>
        </CardLayout>
    )
}

export default Cierre
