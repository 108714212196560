import React from 'react'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"

export const Loading = () => (
    <>
        <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
        />
        Cargando...
    </>
);

export const CardLoading = () => (
    <Card className="shadow-lg border-0" body>
        <Loading />
    </Card>
);

export const FullLoading = () => (
    <Container as={"section"} className="my-auto">
        <Row className="justify-content-center">
            <Col md={5}>
                <CardLoading />
            </Col>
        </Row>
    </Container>
);

export default Loading;