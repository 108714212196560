import React, { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from "../App";

const LoginMenu = () => {
    const { state: authState, dispatch } = React.useContext(AuthContext);

    const handleClick = () => {
        dispatch({
            type: "LOGOUT"
        });
    }

    if (authState.isAuthenticated) {
        return (
            <>
                <NavItem>
                    <NavLink tag={Link} className="btn btn-primary btn-sm text-white" to="#" onClick={handleClick}>Guardar Y Salir</NavLink>
                </NavItem>
            </>
        )
    }

    return null
}

export const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => { setCollapsed((prev => !prev)) };

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">IPP</NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <LoginMenu />
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

